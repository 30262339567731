import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env['RAZZLE_FIREBASE_API_KEY'],
  authDomain: process.env['RAZZLE_FIREBASE_AUTH_DOMAIN'],
  projectId: process.env['RAZZLE_FIREBASE_PROJECT_ID'],
  storageBucket: process.env['RAZZLE_FIREBASE_STORAGE_BUCKET'],
  messagingSenderId: process.env['RAZZLE_FIREBASE_MESSAGING_SENDER_ID'],
  appId: process.env['RAZZLE_FIREBASE_APP_ID'],
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

export { app, auth, db }
