import { render } from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import App from '@/App'

render(
  <RecoilRoot>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </RecoilRoot>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
