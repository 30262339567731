import { Suspense } from 'react'

import Loader from '@/components/Loader/Loader'

const Loadable = (Component) => {
  return function createLoadable(props) {
    return (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    )
  }
}

export default Loadable
