import { SvgIconComponent } from '@mui/icons-material'
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone'

export interface MenuItem {
  link?: string
  icon?: SvgIconComponent
  badge?: string
  items?: MenuItem[]
  name: string
}

export interface MenuItems {
  items: MenuItem[]
  heading: string
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'School',
        link: '/school',
        icon: SchoolTwoToneIcon,
      },
    ],
  },
]

export default menuItems
