import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

const SidebarState = atom<boolean>({
  key: 'sidebarState',
  default: false,
})

export function useSidebar() {
  const sidebarState = useRecoilValue(SidebarState)
  const setSidebarState = useSetRecoilState(SidebarState)

  const toggleSidebar = () => {
    setSidebarState(!sidebarState)
  }

  return {
    sidebarState,
    toggleSidebar,
  }
}
