import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

import { themeCreator } from '@/theme'

const ThemeState = atom<string>({
  key: 'themeState',
  default: localStorage.getItem('appTheme') || 'PureLightTheme',
})

export function useAppTheme() {
  const themeName = useRecoilValue(ThemeState)
  const theme = themeCreator(themeName)

  const setThemeName = useSetRecoilState(ThemeState)
  const setTheme = (themeName: string) => {
    localStorage.setItem('appTheme', themeName)
    setThemeName(themeName)
  }

  return {
    theme,
    setTheme,
  }
}
