import { lazy } from 'react'

import Loadable from '@/components/Loader/Loadable'
import BaseLayout from '@/layouts/BaseLayout'

const Login = Loadable(lazy(() => import('@/pages/Authentication/Login')))

const AuthenticationRoutes = {
  path: '/',
  element: <BaseLayout />,
  children: [
    {
      path: '/login',
      element: <Login />,
    },
  ],
}

export default AuthenticationRoutes
