import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { StylesProvider } from '@mui/styles'

import { useAppTheme } from '@/hooks/useAppTheme'
import useNavigationScroll from '@/hooks/useNavigationScroll'
import Routes from '@/routes'

const App = () => {
  useNavigationScroll()
  const { theme } = useAppTheme()

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <Routes />
        </LocalizationProvider>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default App
