import { CircularProgress, LinearProgress } from '@mui/material'
import { styled } from '@mui/material/styles'

const LinearLoaderWrapper = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1000,
  width: '100%',
})

const CircularLoaderWrapper = styled('div')({
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  zIndex: 1000,
  width: '100%',
  height: '100%',
})

const Loader = () => (
  <>
    <LinearLoaderWrapper>
      <LinearProgress color='primary' />
    </LinearLoaderWrapper>
    <CircularLoaderWrapper>
      <CircularProgress size={64} disableShrink thickness={3} />
    </CircularLoaderWrapper>
  </>
)

export default Loader
