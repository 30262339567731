import { onAuthStateChanged, User } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'

import { auth } from '@/config/firebaseConfig'

const RequireAuth = () => {
  const location = useLocation()
  const [user, setUser] = useState<User>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser)
      setLoading(false)
    })
    return unsubscribe
  }, [loading])

  return (
    !loading &&
    (user ? (
      <Outlet />
    ) : (
      <Navigate to='/login' state={{ from: location }} replace />
    ))
  )
}

export default RequireAuth
