import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import Loadable from '@/components/Loader/Loadable'
import SidebarLayout from '@/layouts/SidebarLayout'
import RequireAuth from '@/routes/RequireAuth'

const School = Loadable(lazy(() => import('@/pages/School')))
const Student = Loadable(lazy(() => import('@/pages/Student/profile')))

const MainRoutes = {
  path: '/',
  element: <RequireAuth />,
  children: [
    {
      element: <SidebarLayout />,
      children: [
        {
          index: true,
          element: <Navigate to='/school' replace />,
        },
        {
          path: '/school',
          children: [
            {
              index: true,
              element: <School />,
            },
            {
              path: '/school/:id',
              element: <Student />,
            },
          ],
        },
      ],
    },
  ],
}

export default MainRoutes
